
import './App.css';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Route,
  BrowserRouter,
} from 'react-router-dom';

import React from 'react'
import Logo from '../src/logo.jpg';
import Logo2 from '../src/logo2.jpg';

import HomePage from './components/HomePage';
import AboutGoPage from './components/AboutGoPage/AboutGoPage';
import NewsGoPage from './components/NewsGoPage/NewsGoPage';


import BottomPage from './components/BottomPage/BottomPage';
import { Routes } from 'react-router';

import ListGoPage from './components/ListGoPage';
import ArticleGoPage from './components/ArticleGoPage';
import DuhGoPage from './components/DuhGoPage';
import PayGoPage from './components/PayGoPage';
import PhotoGoPage from './components/PhotoGoPage';
import SvGoPage from './components/SvGoPage';
import RulesPage from './components/RulesPage';
import AdminListPage from './components/Admin/AdminListPage/AdminListPage';
import AdminNewsPage from './components/Admin/AdminNewsPage/AdminNewsPage';
import NewsGoPageId from './components/NewsGoPage/NewsGoPageId';
import AdminArticlePage from './components/Admin/AdminArticlePage/AdminArticlePage';
import ArticleGoPageId from './components/ArticleGoPage/ArticleGoPageId';
import PhotoGoPageId from './components/PhotoGoPage/PhotoGoPageId';
import AdminPhotoPage from './components/Admin/AdminPhotoPage/AdminPhotoPage';
import PayPage from './components/PayPage';


//import ScrollableAnchor from 'react-scrollable-anchor'


function App() {
  return (
    <BrowserRouter>
      <Container fluid ='true'>
        <Row >
          <Navbar className='Navbar' href='/'>

            <Col sm>
              <img
                src={Logo2}
                className="img"
                alt="Подворье Иерусалимского Патриархата в Москве" />
            </Col>
            <Col sm>
            <h3>Подворье Иерусалимского Патриархата в Москве  </h3>
            </Col>
            <Col sm> 
              <img
                src={Logo}
                className="img"
                alt="Подворье Иерусалимского Патриархата в Москве" />
            </Col>
          </Navbar>
        </Row>
       


        <Row>

          <Navbar expand="lg" sticky="top" className="Navbar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Col>
                <Nav className="justify-content-center">
                  <Nav.Link className='text-white' href="/">Главная</Nav.Link>
                  <NavDropdown className='text-white' title="О Храме" id="basic-nav-dropdown" >
                    <NavDropdown.Item   href="/about" className='Navbar'>История Храма</NavDropdown.Item>
                    <NavDropdown.Item  href="/rules" className='Navbar'>Правила поведения</NavDropdown.Item>
                    <NavDropdown.Item  href="/questions" className='Navbar'>Вопросы-ответы</NavDropdown.Item>
                    <NavDropdown.Item  href="/duh" className='Navbar'>Духовенство</NavDropdown.Item>
                    <NavDropdown.Item  href="/sv" className='Navbar'>Святыни Храма</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link className='text-white' href="/news">Новости</Nav.Link>
                  <Nav.Link className='text-white' href="/list">Расписание</Nav.Link>
                  <Nav.Link className='text-white' href="/articles">Статьи</Nav.Link>
                  <Nav.Link className='text-white' href="/photos">Фото и видео</Nav.Link>
                  <Nav.Link className='text-white' href="/paygo">Пожертвование</Nav.Link>
                </Nav>
              </Col>
            </Navbar.Collapse>
          </Navbar>

        </Row>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutGoPage />} />
          <Route path="/news" element={<NewsGoPage />} />
          <Route path="/news/:id" element={<NewsGoPageId />} />

          <Route path="/list" element={<ListGoPage />} />
          <Route path="/admin/list" element={<AdminListPage />} />
          <Route path="/admin/news" element={<AdminNewsPage />} />
          <Route path="/admin/articles" element={<AdminArticlePage />} />
          <Route path="/admin/photos" element={<AdminPhotoPage />} />



          <Route path="/articles" element={<ArticleGoPage />} />
          <Route path="/articles/:id" element={<ArticleGoPageId />} />

          <Route path="/duh" element={<DuhGoPage />} />
          <Route path="/pay" element={<PayPage />} />
          <Route path="/paygo" element={<PayGoPage />} />

          <Route path="/photos" element={<PhotoGoPage />} />
          <Route path="/photos/:id" element={<PhotoGoPageId />} />

          <Route path="/sv" element={<SvGoPage />} />
          <Route path="/rules" element={<RulesPage />} />


        </Routes>

        <BottomPage/>
      </Container>
    </BrowserRouter >
  );
}



export default App;