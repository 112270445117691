import React, {  useCallback, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import  {Get } from "react-axios";

import "./AdminListPage.css";
import axios from "axios";

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

function AdminListPage() {
  const [show, setShow] = useState(false);
// ---------------------------------------

  const handleClose = () => setShow(false);
// ---------------------------------------

  const handleCloseSave = async () => {
    setShow(false);
// ---------------------------------------
    const response = await axios
      .patch("/api/list", allValues)
      .catch((error) => console.log("Error: ", error));

    if (response && response.data) {
      //this.forceUpdate();
    }
  };
// ----------------------------------------
  const handleShow = (person) => {
    setAllValues({
      id: person._id,
      date: person.date,
      prazdnik: person.prazdnik,
      time1: person.time1,
      time2: person.time2,
      time3: person.time3,
      event1: person.event1,
      event2: person.event2,
      event3: person.event3,
      cleric1: person.cleric1,
      cleric2: person.cleric2,
      cleric3: person.cleric3,
    });

    setShow(true);
  };
  
// ---------------------------------------

  const [allValues, setAllValues] = useState({
    id: "",
    date: "",
    prazdnik: "",
    time1: "",
    time2: "",
    time3: "",
    event1: "",
    event2: "",
    event3: "",
    cleric1: "",
    cleric2: "",
    cleric3: "",
  });
// ---------------------------------------

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  // --------------------------
  let resp = [];
  return (
    <div>
      <Card border="danger" style={{ margin: "2%" }}>
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title>Редактирование расписания Богослужений</Card.Title>
        <code>
          <Get url="/api/list">
            {(error, response, isLoading, makeRequest, axios) => {
              if (error) {
                return (
                  <div>
                    Ошибка загрузки данных: {error.message}{" "}
                    <button
                      onClick={() => makeRequest({ params: { reload: true } })}
                    >
                      Повторить попытку
                    </button>
                  </div>
                );
              } else if (isLoading) {
                return <div>Загрузка...</div>;
              } else if (response !== null) { resp = response.data;
                return (
                  <div>
                    {resp.map((person) => (
                      <React.Fragment key={person._id}>

                      <Table responsive="sm" bordered striped >
                        <thead key={person._id}>
                          <tr >
                            <th>
                              <b>{person.date}</b>
                            </th>
                            <th>
                              <b>{person.prazdnik}</b>{" "}
                            </th>
                            <th>
                              <Button 
                                variant="success"
                                onClick={() => handleShow(person)}
                              >
                                Изменить
                              </Button>{" "}
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>{person.time1}</th>
                            <th>{person.event1}</th>
                            <th>{person.cleric1}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{person.time2}</td>
                            <td>{person.event2}</td>
                            <td>{person.cleric2}</td>
                          </tr>
                          <tr>
                            <td>{person.time3}</td>
                            <td>{person.event3}</td>
                            <td>{person.cleric3}</td>
                          </tr>
                        </tbody>
                      </Table>
                      </React.Fragment>

                    ))}
                    <button
                      onClick={() => makeRequest({ params: { refresh: true } })}
                    >
                      Refresh
                    </button>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Редактирование</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Container>
                          <Form>
                            <Row>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>
                                  {" "}
                                  <b>Дата</b>{" "}
                                </Form.Label>
                                <Form.Control
                                  placeholder="25 Ср"
                                  autoFocus
                                  name="date"
                                  onChange={changeHandler}
                                  value={allValues.date}
                                />

                                <Form.Label>
                                  {" "}
                                  <b>Праздник</b>{" "}
                                </Form.Label>
                                <Form.Control
                                  placeholder="Пасха"
                                  name="prazdnik"
                                  onChange={changeHandler}
                                  value={allValues.prazdnik}
                                />
                              </Form.Group>
                            </Row>

                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Row>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    <b>Время</b>
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="09:00"
                                    name="time1"
                                    onChange={changeHandler}
                                    value={allValues.time1}
                                  />
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    {" "}
                                    <b>Событие</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="Литургия"
                                    name="event1"
                                    onChange={changeHandler}
                                    value={allValues.event1}
                                  />
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    {" "}
                                    <b>Священник</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="о. Михаил"
                                    name="cleric1"
                                    onChange={changeHandler}
                                    value={allValues.cleric1}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    <b>Время</b>
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="09:00"
                                    name="time2"
                                    onChange={changeHandler}
                                    value={allValues.time2}
                                  />
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    {" "}
                                    <b>Событие</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="Литургия"
                                    name="event2"
                                    onChange={changeHandler}
                                    value={allValues.event2}
                                  />
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    {" "}
                                    <b>Священник</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="о. Михаил"
                                    name="cleric2"
                                    onChange={changeHandler}
                                    value={allValues.cleric2}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    <b>Время</b>
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="09:00"
                                    name="time3"
                                    onChange={changeHandler}
                                    value={allValues.time3}
                                  />
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    {" "}
                                    <b>Событие</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="Литургия"
                                    name="event3"
                                    onChange={changeHandler}
                                    value={allValues.event3}
                                  />
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Label>
                                    {" "}
                                    <b>Священник</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="о. Михаил"
                                    name="cleric3"
                                    onChange={changeHandler}
                                    value={allValues.cleric3}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </Form>
                        </Container>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Отменить и Закрыть
                        </Button>
                        <Button variant="primary" onClick={handleCloseSave}>
                          Сохранить и закрыть
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                );
              }
              return <div>Default message before request is made.</div>;
            }}
          </Get>
          </code>          

        </Card.Body>
      </Card>
    </div>
  );
}

export default AdminListPage;
