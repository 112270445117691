import React, { Component } from "react";
import { Card, Button, Table } from "react-bootstrap";
import Logo from "../../background.jpg";

import "./OrderTrebaPage.css";

class OrderTrebaPage extends Component {
  constructor(props) {
    super(props);
    
  }
  render() {
    return (
      <div>
        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Logo}
            style={{
              width: "50%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title>Оплата за {this.props.name}</Card.Title>
            <Card.Text> 
              <Table responsive bordered striped>
                <thead>
                  <tr>
                    <th>
                      <b>{this.props.name}</b>
                    </th>
                    <th>
                      <b>Стоимость</b>{" "}
                    </th>
=                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Проскомидия</td>
                    <td>10 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Литургия</td>
                    <td>80 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Молебен о здравии</td>
                    <td>100 руб. записка</td>
                  </tr>
                  <tr>
                    <td>Молебен о здравии с водосвятием</td>
                    <td>120 руб. записка</td>
                  </tr>
                  <tr>
                    <td>Панихида об упокоении</td>
                    <td>100 руб. записка</td>
                  </tr>
                  <tr>
                    <td>Сорокоуст о здравии/ упокоении</td>
                    <td>400 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Псалтирь 40 дней о здравии/ упокоении</td>
                    <td>500 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Крестины</td>
                    <td>4000 руб. </td>
                  </tr>
                  <tr>
                    <td>Помин 1 месяц о здравии/упокоении</td>
                    <td>200 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Помин 3 месяца о здравии/упокоении</td>
                    <td>500 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Помин 6 месяцев о здравии/упокоении</td>
                    <td>900 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Помин 12 месяцев о здравии/упокоении</td>
                    <td>2000 руб. имя</td>
                  </tr>
                  <tr>
                    <td>Заочное отпевание</td>
                    <td>2000 руб. </td>
                  </tr>
                  <tr>
                    <td>Отпевание</td>
                    <td>3000 руб. </td>
                  </tr>
                  <tr>
                    <td>Сорокоуст в Иерусалим о здравии/упокоении</td>
                    <td>500 руб. имя</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default OrderTrebaPage;
