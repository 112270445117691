import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from '../../duh.jpg';


import "./DuhPage.css";

class DuhPage extends Component {
  render() {
    return (
      <div>
   <Card border="danger" style={{  margin: '2%' }}>
  <Card.Img variant="top" src={Logo} />
  <Card.Body>
    <Card.Title>Духовенство</Card.Title>
    <Card.Text>
   О духовенстве Храма.
    </Card.Text>
    <a href="/duh" class="btn btn-danger">
              Перейти
            </a>   </Card.Body>
</Card>
      </div>
    );
  }
}

export default DuhPage;
