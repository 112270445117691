import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from "../../pay.jpg";

import "./PayPage.css";

class PayPage extends Component {
  render() {
    return (
      <div>
        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img variant="top" src={Logo} />
          <Card.Body>
            <Card.Title>Пожертвование</Card.Title>
            <Card.Text>Пожертвовать на Храм, подать записку.</Card.Text>
            <a href="/paygo" className="btn btn-danger">
              Перейти
            </a>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default PayPage;
