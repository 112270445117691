import React, { useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  Form,
  Container,
  Row,
} from "react-bootstrap";

import { Get } from "react-axios";
import "react-quill/dist/quill.snow.css";
import "./AdminPhotoPage.css";
import axios from "axios";

function AdminPhotoPage() {
  let arr = [];
  const [show, setShow] = useState(false);
  // ---------------------------------------

  const handleClose = () => setShow(false);
  // ---------------------------------------

  const handleCloseSave = async () => {
    // setAllValues({text:value});
    setShow(false);
    // ---------------------------------------
    const response = await axios
      .patch("/api/photos", allValues)
      .catch((error) => console.log("Error: ", error));

    if (response && response.data) {
      //this.forceUpdate();
    }
  };
  // ---------------------------------------
  const handleNew = async () => {
    const emptyValues = {
      date: new Date().toLocaleDateString("ru-RU"),
      title: "Новый фотоальбом",
      path: "",
      photos: [],
    };
    emptyValues.path += emptyValues.date.split(".").join("");
    //console.log(emptyValues.path);
    // eslint-disable-next-line no-unused-vars
    const cDate = new Date().toLocaleDateString("ru-RU");
    let nDate = window.prompt("Введите дату нового фотоальбома", cDate);
    if (nDate) {
      emptyValues.path += emptyValues.date.split(".").join("");

      setAllValues("");
      const responseNew = await axios
        .post("/api/photos", emptyValues)
        .catch((error) => console.log("Error: ", error));
    }
  };
  // ----------------------------------------
  const handleShow = (person) => {
    setAllValues({
      id: person._id,
      date: person.date,
      photos: person.photos,
      title: person.title,
      path: person.path,
    });
    setValue(person.text);
    setShow(true);
  };

  // ---------------------------------------

  const [allValues, setAllValues] = useState({
    id: "",
    date: "",
    title: "",
    path: "/",
    photos: [],
  });
  // ---------------------------------------

  const changeHandler = (e) => {
    //console.log(e.target.type);
    setAllValues({
      ...allValues, // spreading the unchanged values
      [e.target.name]: e.target.value, // changing the state of *changed value*
    });
    //setAllValues({text:value});
  };
  // ---------------------------------------------- Upload files
  const uploadHandler = async (event) => {
    const data = new FormData();

    for (let i = 0; i < event.target.files.length; i++) {
      data.append("file", event.target.files[i]);
      arr.push(event.target.files[i].name);
    }

    const path = "/api/upload?path=" + allValues.path;

    const res = await axios.post(path, data).then((res) => {
      setAllValues({ ...allValues, photos: arr });
    });
    if (!res) alert("Файлы загружены!");
    else alert("Ошибка загрузки файлов!!");
  };
  //-----------------------------------------------
  const onContentChange = (content) => {
    setValue(content);
    setAllValues({ ...allValues, text: content });
    //console.log(allValues);
  };

  const [value, setValue] = useState(allValues.text);

  let resp = [];
  // --------------------------

  return (
    <div>
      <Card border="danger" style={{ margin: "2%" }}>
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title>Редактирование фото</Card.Title>
          <code>
            <Get url="/api/photos">
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Ошибка загрузки данных: {error.message}{" "}
                      <button
                        onClick={() =>
                          makeRequest({ params: { reload: true } })
                        }
                      >
                        Повторить попытку
                      </button>
                    </div>
                  );
                } else if (isLoading) {
                  return <div>Загрузка...</div>;
                } else if (response !== null) {
                  resp = response.data;
                  return (
                    <div>
                      <Button variant="danger" onClick={handleNew}>
                        Добавить
                      </Button>{" "}
                      {resp.map((person) => (
                        <React.Fragment key={person._id}>
                          <Table responsive="sm" bordered striped>
                            <thead key={person._id}>
                              <tr>
                                <th>
                                  <b>{person.date}</b>
                                </th>
                                <th>
                                  <b>{person.title}</b>{" "}
                                </th>

                                <th>
                                  <Button
                                    variant="success"
                                    onClick={() => handleShow(person)}
                                  >
                                    Изменить
                                  </Button>{" "}
                                </th>
                              </tr>
                            </thead>
                          </Table>
                        </React.Fragment>
                      ))}
                      <button
                        onClick={() =>
                          makeRequest({ params: { refresh: true } })
                        }
                      >
                        Обновить
                      </button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        fullscreen={"true".toString()}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Редактирование</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Form>
                              <Row>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>
                                    <b>Дата</b>
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="25 Ср"
                                    disabled
                                    name="date"
                                    onChange={changeHandler}
                                    value={allValues.date}
                                  />
                                  <Form.Label>
                                    {" "}
                                    <b>Название</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="Название"
                                    name="title"
                                    onChange={changeHandler}
                                    value={allValues.title}
                                  />
                                  <Form.Label>
                                    {" "}
                                    <b>Картинки будут перезаписаны</b>{" "}
                                  </Form.Label>
                                  <div className="form-group">
                                    <input
                                      type="file"
                                      name="file"
                                      placeholder="Upload"
                                      encType="multipart/form-data"
                                      multiple
                                      onChange={uploadHandler}
                                    />
                                    <small className="form-text">
                                      The image must not be bigger then 5MB and
                                      only JPG\JPEG\PNG types
                                    </small>
                                  </div>
                                </Form.Group>
                              </Row>
                            </Form>
                          </Container>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Отменить и Закрыть
                          </Button>
                          <Button variant="primary" onClick={handleCloseSave}>
                            Сохранить и закрыть
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  );
                }
                return <div>Default message before request is made.</div>;
              }}
            </Get>
          </code>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AdminPhotoPage;
