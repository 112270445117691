import React, { useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import ReactQuill from "react-quill";
import queryString from "query-string";
import { Get } from "react-axios";

import "./ArticleGoPage.css";
import { Link } from "react-router-dom";

function ArticleGoPageId(props) {
  const [show, setShow] = useState(false);
  // ---------------------------------------

  const handleShow = (id) => {
    console.log(id);
    setShow(true);
  };

  // ---------------------------------------
  let modules = {
    toolbar: [
     
    ],
  };
  // ---------------------------------------

  const handleHide = () => {
    setShow(false);
  };

  // ---------------------------------------

  let urlPath = "";
  urlPath = "/api/" + window.location.pathname;
  let resp = [];

  return (
    <div>
      <Card border="danger" style={{ margin: "2%" }}>
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title className="red-text">Православные статьи </Card.Title>
          <code>
            <Get url={urlPath}>
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Ошибка загрузки данных: {error.message}{" "}
                      <button
                        onClick={() =>
                          makeRequest({ params: { reload: true } })
                        }
                      >
                        Повторить попытку
                      </button>
                    </div>
                  );
                } else if (isLoading) {
                  return <div>Загрузка...</div>;
                } else if (response !== null) {
                  resp = response.data;
                  return (
                    <Container>
                      <Row>
                        <a href="/articles"> К списку статей</a>
                       
                      </Row>
                      <Row >
                        
                          <b className="red-text">{resp.date}</b>
                       
                      </Row>
                      <Row >
                        <b className="red-text">{resp.title}</b> <br/><br/>
                      </Row>

                      <Row >
                     
                              <div className="black-text" dangerouslySetInnerHTML={{__html: resp.text}}></div>

                      </Row>
                      <Row>
                        <a href="/articles"> К списку статей</a>
                       
                      </Row>
                    </Container>
                  );
                }
                return <div>Default message before request is made.</div>;
              }}
            </Get>
          </code>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ArticleGoPageId;
