import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from '../../photo.jpg';


import "./PhotoPage.css";

class PhotoPage extends Component {
  render() {
    return (
      <div>
   <Card border="danger" style={{  margin: '2%' }}>
  <Card.Img variant="top" src={Logo} />
  <Card.Body>
    <Card.Title>Фото и видео</Card.Title>
    <Card.Text>
      Фотографии и видео с Богослужений.
    </Card.Text>
    <a href="/photos" class="btn btn-danger">
              Перейти
            </a>  </Card.Body>
</Card>
      </div>
    );
  }
}

export default PhotoPage;
