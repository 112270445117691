import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from "../../about.jpg";

import "./AboutPage.css";

class AboutPage extends Component {
  render() {
    return (
      <div>
        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img variant="top" src={Logo} />
          <Card.Body>
            <Card.Title>О Храме</Card.Title>
            <Card.Text>Информация о Храме.</Card.Text>
            <a href="/about" class="btn btn-danger">
              Перейти
            </a>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default AboutPage;
