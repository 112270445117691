import React, { Component, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import ReactQuill from "react-quill";

import { Get } from "react-axios";

import "./ArticleGoPage.css";
import { Link } from "react-router-dom";

function ArticleGoPage(props) {
  const [show, setShow] = useState(false);
  // ---------------------------------------

  const handleShow = (id) => {
    console.log(id);
    setShow(true);
  }
  
  // ---------------------------------------

  const handleHide =  () => {
    setShow(false);
   
  };
  

  // ---------------------------------------

 
  


  let resp = [];
 

  return (
    <div>
      <Card border="danger" style={{ margin: "2%" }}>
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title className="red-text">Православные статьи</Card.Title>
          <code>
            <Get url="/api/articles">
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Ошибка загрузки данных: {error.message}{" "}
                      <button
                        onClick={() =>
                          makeRequest({ params: { reload: true } })
                        }
                      >
                        Повторить попытку
                      </button>
                    </div>
                  );
                } else if (isLoading) {
                  return <div>Загрузка...</div>;
                } else if (response !== null) {
                  resp = response.data;
                  return (
                    <div>
                     <Table responsive="sm"  borderless hover>
                      {resp.map((person) => (
                        <React.Fragment key={person._id}>
                            <tbody key={person._id}>
                              <tr>
                            
                                <td>
                                  <b className="red-text">{person.date}</b>
                                </td>
                                <td>
                                <Link to={`/articles/${person._id}`}>{person.title}</Link>
                                </td>
                               
                              </tr>
                            </tbody>
                        </React.Fragment>
                      ))}
                    </Table> 
                    </div>
                  );
                }
                return <div>Default message before request is made.</div>;
              }}
            </Get>
          </code>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ArticleGoPage;




