import React, { Component } from "react";
import { Card, Button, Row } from "react-bootstrap";
import Stefan from "../../stefan.jpg";
import Mihail from "../../mihail.jpg";
import Vladimir from "../../vladimir.jpg";
import Viktor from "../../viktor.jpg";
import Dimitri from "../../dimitri.jpg";

import "./DuhGoPage.css";

class DuhGoPage extends Component {
  render() {
    return (
      <div>
        <h3 className="red-text">Духовенство нашего Храма</h3>
        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Stefan}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Архимандрит Стефан</Card.Title>
            <p>
              Архимандрит Стефан (Диспиракис) родился 4 ноября 1957 г.-
              настоятель храма Воскресения Словущего на Арбате Иерусалимского
              подворья.
            </p>

            <p>
              Родился в Греции на о.Крит. В 1971 г. уехал в Иерусалим, там
              закончил школу. В 1978 г. рукоположен в диакона. В 1979 г. был
              рукоположен в иерея, а в 1981 г. в архимандрита. С 1979 по 1981
              гг. служил в храме Гроба Господня. В 1982г. служил иегуменом в
              монастыре Святого Георгия Победоносца в Лиде. (Там же находятся
              мощи Святого Георгия Победоносца).
            </p>

            <p>
              В 1983-1987 гг. учился в университете в Афинах на факультете
              богословия и в это же время служил в Иерусалимском подворье в
              Афинах. В 1987 г. вернулся в Иерусалим и был назначен хранителем
              библиотечного фонда Патриархии Иерусалима.
            </p>

            <p>
              С 1989 по 2000 гг. возглавлял экономический отдел Патриархии
              Иерусалима. А с 1999 по 2003 гг. занимал должность главного
              секретаря Патриарха Иерусалима. День тезоименитства 9 января.
            </p>

            <p>
              С августа 2003 года возглавил Иерусалимское подворье в Москве.
            </p>
            <p>
              День тезоименитства - 9 января.
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Mihail}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Протоиерей Михаил</Card.Title>
            <p>
              Протоиерей Михаил Леонидович Крутинь родился 26 февраля 1956 года
              в семье профессиональных церковных певчих.
            </p>

            <p>
              В 1979 году поступил в Ленинградскую Духовную семинарию. В 1981
              году окончил ЛДС по первому разряду и награжден премией имени
              митрополита Ленинградского и Новгородского Никодима (Ротова).
              Решением преподавательской корпорации принят в число студентов
              Ленинградской Духовной Академии без сдачи вступительных экзаменов.
            </p>

            <p>
              15 мая 1981 года ректором Ленинградских Духовных Академии и
              семинарии архиепископом Выборгским Кириллом (ныне –Святейший
              Патриарх Московский и всея Руси) рукоположен в сан диакона.
            </p>

            <p>
              10 марта 1985 года митрополитом Ленинградским и Новгородским
              Антонием (Мельниковым) рукоположен в сан священника с возложением
              набедренника.
            </p>

            <p>
              В 1991 году возведен в сан протоиерея.С 1997 года – духовник
              сестричества в честь свт. Петра, митрополита Московского и всея
              Руси.
            </p>

            <p>
              2001 г. – в дополнение к послушанию помощника Экзарха Патриарха
              Св. града Иерусалима и всея Палестины в России (Иерусалимское
              Подворье в Москве на Арбате) назначен настоятелем храма-часовни во
              имя иконы Божией Матери «Неопалимая Купина» на Пречистенке при
              Главном Управлении МЧС России по г. Москве. В 2005 году награжден
              крестом с украшениями. День Ангела – 21 ноября.
            </p>

            <p>Имеет награды:</p>

            <p>
              Церковные: орден св. равноап. кн. Владимира III степени. орден св.
              блгв. кн. Даниила III степени. Светские: медаль МЧС России «За
              содружество во имя спасения», медаль МВД России «За верность
              долгу», медаль МЧС России «200 лет профессиональной пожарной
              охране Москвы», медаль МВД России «200 лет МВД России», памятная
              юбилейная медаль «70 лет Главному Управлению МЧС России по
              Москве».
            </p>
            <p>
              День тезоименитства - 21 ноября.
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Vladimir}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Протоиерей Владимир</Card.Title>

            <p>
              Протоиерей Владимир Михайлович Гулин родился 8 августа 1966 года в
              г. Алуевск, Луганской области.
            </p>

            <p>
              В 1994 г. окончил Московскую Духовную Семинарию, 21 мая1994 г.
              возведен во чтеца, 9 июля 1996 г. во диакона, служил в храме
              Живоначальной Троицы в Свиблове. 22 марта 1998г. Святейшим
              Патриархом Алексием II рукоположен в сан священника и с 8 апреля
              1998 г. клирик храма Воскресения Словущего Иерусалимского подворья
              в Москве.
            </p>

            <p>Преподаватель Воскресной школы с 2003 года.</p>

            <p>
              Церковные награды: набедренник, камилавка, наперсный крест
              (2008г.)
            </p>
            <p>День тезоименитства: 28 июля.</p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Viktor}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Иерей Виктор</Card.Title>

            <p>
              Священник Виктор Васильевич Зогий, родился 10 января 1977 года в
              городе Клинцы Брянской области. Вырос в Смоленской области.
            </p>

            <p>
              С 1984 года по 1994 год обучался в средней школе №1 поселка
              Озерный. В старших классах начал ходить в храм и регулярно
              исполнять послушание чтеца и певца на приходе Рождества Богородицы
              пос. Озерный.
            </p>

            <p>
              В августе 1994 года поступил в Смоленскую духовную семинарию,
              которую закончил в 1998 году по первому разряду.
            </p>

            <p>
              В 1998 г. Отделом внешних церковных связей направлен для обучения
              на богословском факультете Афинского Университета, который
              закончил в 2003 году.
            </p>

            <p>
              В июле 2003 года был принят в Отдел внешних церковных связей
              Московского Патриархата в качестве сотрудника Сектора
              загранучреждений.
            </p>

            <p>
              4 сентября 2005 г. сочетался церковным браком с Запольской Анной
              Геннадьевной.
            </p>

            <p>
              4 ноября того же года митрополитом Смоленским и Калининградским
              Кириллом (ныне Святейшим Патриархом Московским и всея Руси) в
              храме Живоначальной Троицы в Хорошеве рукоположен в сан диакона, а
              25 декабря в Успенском кафедральном соборе г. Смоленска в сан
              священника.
            </p>

            <p>
              11 марта 2006 г. по представлению Председателя Отдела Внешних
              Церковных связей митрополита Кирилла, указом Святейшего Патриарха
              Алексия отправлен на служение в русскоязычном приходе св. Стилиана
              в г. Лимассоле. Награжден набедренником и наперсным крестом.
            </p>

            <p>
              В 2015 г. возвращен из загранслужения для дальнейшего несения
              послушания в России. 1 июня 2016 г. Указом Патриарха Кирилла
              зачислен в клир г. Москвы и назначен штатным священником храма
              Воскресения Словущего (ап. Филиппа) – Патриаршего подворья на
              Арбате, Представительства Иерусалимской Православной Церкви при
              Московском Патриаршем Престоле.
            </p>
            <p>
              День тезоименитства - 13 февраля.
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Dimitri}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Протодиакон Димитрий</Card.Title>

            <p>
              {" "}
              Протодиакон Дмитрий Александрович Грибов родился 18 июля 1968 года
              в г. Москве.
            </p>

            <p>
              Окончил Московскую Духовную Семинарию. 7 декабря 1993 г.
              рукоположен в сан диакона Святейшим Патриархом Алексием II. Им же
              возведен в сан протодиакона 2 декабря 2008 г. Клирик храма
              Воскресения Словущего в Москве с 28 августа 1994 г.
            </p>

            <p>День тезоименитства: 22 августа.</p>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default DuhGoPage;
