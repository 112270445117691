import React, { Component } from "react";
import { Card, CardGroup } from "react-bootstrap";
import { YMaps, Map } from "react-yandex-maps";
import "./BottomPage.css";

class BottomPage extends Component {
  render() {
    return (
      <div className="bottom">
        <CardGroup>
          <Card border="danger" style={{ margin: "2%" }}>
            <Card.Body>
              <Card.Text>
               <b> &copy; Подворье Патриарха Московского и всея Руси храма Воскресения Словущего на Арбате г.Москвы 
                2022 </b>
                <br />
                Россия, г. Москва, Филлиповский пер., 20.
                <br />
                Телефон: +7 (495) 690-57-01 <br />
                e-mail: info@jerusalem-podvorie.ru <br />
                <i className="bi bi-telegram"></i>Telegram: <a href="https://t.me/jerusalem_podvorie">Перейти</a>
               
              </Card.Text>
            </Card.Body>
          </Card>
          <Card border="danger" style={{ margin: "2%" }}>
            <Card.Body >
              <YMaps>
                Наш Храм на карте
                <Map
                  defaultState={{
                    center: [55.750175, 37.598869],
                    zoom: 17,
                  }}
                  width={"95%"}
                 
                />
              </YMaps>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    );
  }
}

export default BottomPage;
