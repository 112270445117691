import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from '../../article.jpg';


import "./ArticlePage.css";

class ArticlePage extends Component {
  render() {
    return (
      <div>
   <Card border="danger" style={{  margin: '2%' }}>
  <Card.Img variant="top" src={Logo} />
  <Card.Body>
    <Card.Title>Статьи</Card.Title>
    <Card.Text>
   Полезные статьи и публикации для православных христиан.
    </Card.Text>
    <a href="/articles" class="btn btn-danger">
              Перейти
            </a>  </Card.Body>
</Card>
      </div>
    );
  }
}

export default ArticlePage;
