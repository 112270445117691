import React, { Component } from "react";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import AboutPage from "../AboutPage";
import ArticlePage from "../ArticlePage";
import ListPage from "../ListPage/ListPage";
import NewsPage from "../NewsPage/NewsPage";
import PayPage from "../PayPage";
import PhotoPage from "../PhotoPage/PhotoPage";
import DuhPage from "../DuhPage/DuhPage";
import "./HomePage.css";
import SvPage from "../SvPage/SvPage";
import {Helmet} from "react-helmet";
import ListGoPage from "../ListGoPage/ListGoPage";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row xs={1} md={2}  lg={3}>
            <Col>
              <PayPage />
            </Col>
            <Col>
              <NewsPage />
            </Col>
            <Col>
           <ListPage/>
            </Col>
          </Row>

          {/* Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop */}
          <Row xs={1} md={2} lg={3}>
            <Col>
              <AboutPage />
            </Col>
            <Col>
              <ArticlePage />
            </Col>
            <Col>
              <PhotoPage />
            </Col>
          </Row>

          <Row xs={1} md={2} lg={3}>
            <Col>
              <DuhPage />
            </Col>
            <Col>
              <SvPage />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomePage;
