import React, { useCallback, useState } from "react";
import { Card,
  Button,
  Table,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import ReactQuill from "react-quill";
import queryString from "query-string";
import { Get } from "react-axios";

import "./PhotoGoPage.css";
import { Link } from "react-router-dom";

import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";

function PhotoGoPageId(props) {
  const [show, setShow] = useState(false);
  // ---------------------------------------

  const handleShow = (id) => {
    console.log(id);
    setShow(true);
  };

  let photos = [ ];

  // ---------------------------------------
  let modules = {
    toolbar: [],
  };
  // ---------------------------------------

  const handleHide = () => {
    setShow(false);
  };

  // ---------------------------------------
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  let urlPath = "";
  urlPath = "/api/" + window.location.pathname;
  let resp = [];

  return (
    <div>
      <Card border="danger" style={{ margin: "2%" }}>
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title className="red-text">Фото и видео </Card.Title>
          <code>
            <Get url={urlPath}>
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Ошибка загрузки данных: {error.message}{" "}
                      <button
                        onClick={() =>
                          makeRequest({ params: { reload: true } })
                        }
                      >
                        Повторить попытку
                      </button>
                    </div>
                  );
                } else if (isLoading) {
                  return <div>Загрузка...</div>;
                } else if (response !== null) {
                  resp = response.data;
                  const path = response.data.path;
                  resp.photos.map((i) =>
                    photos.push({
                      src:
                        "https://jerusalem-podvorie.ru/public/" +
                        path +
                        "/" +
                        i,
                        sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
                      width: 1,
                      height: 1,
                    })
                  );

                  return (
                    <Container>
                      <Row>
                        <a href="/photos"> К списку фото</a>
                      </Row>
                      <Row>
                        <b className="red-text">{resp.date}</b>
                      </Row>
                      <Row>
                        <b className="red-text">{resp.title}</b> <br />
                        <br />
                      </Row>

                      <Row >
                        <Gallery photos={photos} onClick={openLightbox} />
                        <ModalGateway>
                          {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                              <Carousel
                              
                                views={photos.map((x) => ({
                                  ...x,
                                  srcset: x.srcSet,
                                  caption: x.title,
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                      </Row>
                      <Row>
                        <a href="/photos"> К списку фото</a>
                      </Row>
                    </Container>
                  );
                }
                return <div>Default message before request is made.</div>;
              }}
            </Get>
          </code>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PhotoGoPageId;
