import React, {  useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import { Get } from "react-axios";
import "react-quill/dist/quill.snow.css";
import "./AdminArticlePage.css";
import axios from "axios";
import ReactQuill from "react-quill";
import theme from "react-quill/dist/quill.snow.css";

function AdminArticlePage() {
  const [show, setShow] = useState(false);
  // ---------------------------------------

  const handleClose = () => setShow(false);
  // ---------------------------------------

  const handleCloseSave = async () => {
    // setAllValues({text:value});
    setShow(false);
    // ---------------------------------------
    const response = await axios
      .patch("/api/articles", allValues)
      .catch((error) => console.log("Error: ", error));

    if (response && response.data) {
      //this.forceUpdate();
    }
  };
  // ---------------------------------------
  const handleNew = async () => {
    const emptyValues = ({
        date: new Date().toLocaleDateString(),
        title: "Новая статья",
        text: "Статья",
      });
    // eslint-disable-next-line no-unused-vars
    if (window.confirm("Добавить новость?")) {
      setAllValues("");
      const responseNew = await axios
        .post("/api/articles", emptyValues)
        .catch((error) => console.log("Error: ", error));
    }
  };
  // ----------------------------------------
  const handleShow = (person) => {
    setAllValues({
      id: person._id,
      date: person.date,
      text: person.text,
      title: person.title,
    });
    setValue(person.text);

    setShow(true);
  };

  // ---------------------------------------

  const [allValues, setAllValues] = useState({
    id: "",
    date: "",
    title: "",
    text: "",
  });
  // ---------------------------------------

  const changeHandler = (e) => {
    //console.log(e.target.type);
    setAllValues({
      ...allValues, // spreading the unchanged values
      [e.target.name]: e.target.value, // changing the state of *changed value*
    });
    //setAllValues({text:value});
  };

  const onContentChange = (content) => {
    setValue(content);
    setAllValues({ ...allValues, text: content });
    //console.log(allValues);
  };

  const [value, setValue] = useState(allValues.text);

  let resp = [];
  // --------------------------
  let modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <div>
      <Card border="danger" style={{ margin: "2%" }}>
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title>Редактирование статей</Card.Title>
          <code>
            <Get url="/api/articles">
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Ошибка загрузки данных: {error.message}{" "}
                      <button
                        onClick={() =>
                          makeRequest({ params: { reload: true } })
                        }
                      >
                        Повторить попытку
                      </button>
                    </div>
                  );
                } else if (isLoading) {
                  return <div>Загрузка...</div>;
                } else if (response !== null) {
                  resp = response.data;
                  return (
                    <div>
                        <Button
                                    variant="danger"
                                    onClick={handleNew}
                                  >
                                    Добавить
                                  </Button>{" "}
                      {resp.map((person) => (
                        <React.Fragment key={person._id}>
                          <Table responsive="sm" bordered striped>
                            <thead key={person._id}>
                              <tr>
                                <th>
                                  <b>{person.date}</b>
                                </th>
                                <th>
                                  <b>{person.title}</b>{" "}
                                </th>
                                <th>
                                  <Button
                                    variant="success"
                                    onClick={() => handleShow(person)}
                                  >
                                    Изменить
                                  </Button>{" "}
                                </th>
                              </tr>
                            </thead>
                          </Table>
                        </React.Fragment>
                      ))}
                      <button
                        onClick={() =>
                          makeRequest({ params: { refresh: true } })
                        }
                      >
                        Обновить
                      </button>

                      <Modal
                        show={show}
                        onHide={handleClose}
                        fullscreen={"true".toString()}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Редактирование</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Form>
                              <Row>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>
                                   
                                    <b>Дата</b>
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="25 Ср"
                                    autoFocus
                                    name="date"
                                    onChange={changeHandler}
                                    value={allValues.date}
                                  />
                                  <Form.Label>
                                    {" "}
                                    <b>Название</b>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder="Название"
                                    name="title"
                                    onChange={changeHandler}
                                    value={allValues.title}
                                  />
                                  <Form.Label>
                                    {" "}
                                    <b>Текст новости (картинка до 320*568 размер меньше 50Kb)</b>{" "}
                                  </Form.Label>

                                  <ReactQuill
                                    name="text"
                                    value={value}
                                    onChange={onContentChange}
                                    theme="snow"
                                    modules={modules}
                                  />
                                </Form.Group>
                              </Row>
                            </Form>
                          </Container>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Отменить и Закрыть
                          </Button>
                          <Button variant="primary" onClick={handleCloseSave}>
                            Сохранить и закрыть
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  );
                }
                return <div>Default message before request is made.</div>;
              }}
            </Get>
          </code>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AdminArticlePage;
