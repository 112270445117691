import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from "../../sv.jpg";

import "./SvPage.css";

class SvPage extends Component {
  render() {
    return (
      <div>
        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img variant="top" src={Logo} />
          <Card.Body>
            <Card.Title>Святыни Храма</Card.Title>
            <Card.Text>Информация о Святынях Храма.</Card.Text>
            <a href="/sv" class="btn btn-danger">
              Перейти
            </a>           </Card.Body>
        </Card>
      </div>
    );
  }
}

export default SvPage;
