import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from "../../isthram.jpg";

import "./AboutGoPage.css";

class AboutGoPage extends Component {
  render() {
    return (
      <div>
        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img variant="top" src={Logo} style={{ width: "50%", display: "block", margin: '2% auto', borderRadius: "5%" }} />
          <Card.Body>
            <Card.Title className="red-text">О Храме</Card.Title>
            <Card.Text className="red-text">История нашего Храма</Card.Text>
            <p>
              История храма Воскресения Словущего уходит в глубь веков. По
              преданию, уже со второй половины XVI века на этом месте стояла
              церковь во имя святого апостола Филиппа. Основана она была, по
              всей видимости, самим митрополитом Московским и всея Руси Филиппом
              (Колычевым) в Иконном переулке при его загородном доме и,
              вероятно, была деревянной, как многие древние русские храмы.
              Существующий ныне каменный храм был воздвигнут в 1688 году при
              царе Алексее Михайловиче его боярином Иваном Косьминым и 2 ноября
              того же года освящён Патриархом Иоакимом.
            </p>
            <p>
              История жизни храма в XVIII столетии покрыта тайной. И только из
              глубины XIX века (особенно его второй половины) смогли подняться к
              нам редкие и драгоценные сведения. В период Отечественной войны
              1812 года церковь святого апостола Филиппа разделила судьбу многих
              московских храмов. Она была ограблена и осквернена французскими
              войсками. Сильно обветшавшая, с малочисленным приходом,
              находившаяся в самом центре сожжённой, но непокорённой столицы,
              она внушала мысль о невозможности своего восстановления. Указ
              Святейшего Синода от 26 июля 1818 года гласил, что Филипповскую
              церковь «предполагается упразднить».
            </p>
            <p>
              Но Богом было уготовано иное. Небольшому храму в тихом арбатском
              переулке Его дивный Промысел определил стать Иерусалимским
              подворьем, местом пребывания и служения представителей братства
              Святого Живоносного Гроба Господня. В 1808 году в Иерусалиме
              вспыхнул пожар, почти полностью уничтоживший великую святыню —
              храм Гроба Господня. Патриарх Иерусалимский Поликарп обратился за
              помощью в восстановлении храма к другим Православным Церквам. В
              1817 году он направил просьбу к Императору Александру I о
              даровании Иерусалимскому Патриархату подворья в Москве, где могли
              бы останавливаться монахи братства Святого Гроба Господня для
              сбора пожертвований на восстановление сгоревшего храма. Русская
              Православная Церковь откликнулась на этот призыв; так в Москве
              было учреждено Иерусалимское подворье. Патриарх Иерусалимский
              прислал в дар подворью часть Древа Животворящего Креста Господня и
              десницу великомученика Евстафия Плакиды. Эти великие святыни и
              доныне хранятся в храме.
            </p>
            <p>
              Первым настоятелем Иерусалимского подворья стал архимандрит
              Арсений. Много сил положил он на благое дело реставрации и ремонта
              храма. Был отстроен правый придел во имя Иерусалимской иконы
              Божией Матери и Николая Чудотворца, куплены прилегающая к храму со
              стороны Пречистенского бульвара земля и стоящий на ней каменный
              дом. В 1822 году, когда все реставрационные и строительные работы
              успешно завершились, главный придел храма был переименован в честь
              Обновления (освящения) Храма Воскресения Христова в Иерусалиме —
              Воскресения Словущего, а левый придел — в честь святого апостола
              Филиппа. В 1849–1852 годы этот придел был перестроен и 20 сентября
              1852 года торжественно освящён митрополитом Московским Филаретом
              (Дроздовым). Сохранилась речь, произнесённая им в тот памятный
              день. В ней выдающийся иерарх Русской Православной Церкви
              подчеркнул, что это событие пронизано живой радостью «соединения
              Святых Божиих Церквей, ежедневно испрашиваемого нами в молитвах».
            </p>
            <p>
              Живым свидетельством молитвенного соединения двух святых Божиих
              Церквей — Русской и Иерусалимской — были бесценные дары,
              посылаемые Патриархами Иерусалимскими своей русской пастве. В 1853
              году, в бытность настоятелем архимандрита Филарета, храм принял в
              дар великую святыню — частицу мощей апостола Иакова, брата
              Господня, первого иерарха Иерусалимского. После кончины
              архимандрита Филарета настоятелями Иерусалимского подворья были
              архимандрит Григорий (1868–1877), а затем архимандрит Никодим
              (1877–1882), впоследствии ставший Иерусалимским Патриархом. Во
              второй год его настоятельства Патриарх Иерусалимский Иерофей
              прислал в дар подворью частицы мощей угодников Божиих:
              священномученика Харалампия, великомученика и целителя
              Пантелеимона, великомученицы Варвары и великомученика Галактиона,
              мученика Трифона. В 1880 году Патриарх вновь приносит в дар
              русской пастве бесценные сокровища: частицы мощей святителя Иоанна
              Милостивого, патриарха Александрийского, великомученика Никиты,
              великомученицы Параскевы Пятницы, бессребреника и чудотворца Космы
              и преподобного Алипия столпника. Для этих святых даров архимандрит
              Никодим специально устроил позолоченный ковчег. В нём они
              находятся и по сей день, и чудотворная благодать мученических
              мощей изливается на всякого, с верою к ним приступающего.
            </p>
            <p>
              В 1917 году Иерусалимское подворье было упразднено, но церковь и в
              годы советской власти не закрывалась для прихожан. С 1930-х годов
              в храме служил известный протодиакон Михаил (Холмогоров). В 1960-х
              — 1990-х годах совершал своё служение многими почитаемый
              протоиерей Василий Серебренников.
            </p>
            <p>
              С 1989 года храм Воскресения Словущего вновь стал именоваться
              храмом Иерусалимского подворья; настоятелем назначили архимандрита
              ныне почившего Феофилакта (Георгиадиса), архиепископа Иорданского. В 2001
              году его сменил архимандрит Феофил (Яннопулос), ныне — Патриарх
              Иерусалимский и всея Палестины. С 2003 года настоятелем является
              архимандрит Стефан (Диспиракис).
            </p>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default AboutGoPage;
