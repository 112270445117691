import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Kazan_icon from "../../kazan_icon.jpg";
import Nech_rad from "../../nech_rad.jpg";
import Skorop_icon from "../../skorop_icon.jpg";
import Ierusalem_icon from "../../ierusalem_icon.jpg";
import Vzpog_icon from "../../vzpog_icon.jpg";
import Ahtyr_icon from "../../ahtyr_icon.jpg";
import Niko from "../../niko.jpg";

import "./SvGoPage.css";

class SvGoPage extends Component {
  render() {
    return (
      <div>
        <h4 className="red-text">Святыни нашего Храма</h4>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Kazan_icon}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">
              Казанская икона Божьей Матери
            </Card.Title>

            <p>
              Дни празднования: 8 июля (21 июля н.с.) и 22 октября (4 ноября
              н.с.)
            </p>
            <p>
              Празднование Божией Матери 8 июля (21 июля по новому стилю) в
              честь Ее Казанской иконы имеет своим началом чудесное явление ее в
              Казани в 1579 г. Через некоторое время после покорения Казани
              Иваном Грозным и успешного распространения христианства,
              магометане стали оказывать сильное сопротивление. Пожар 1579 г.,
              истребивший половину Казанского кремля, они расценили как гнев
              «русского Бога». Именно в это время для укрепления Православия в
              Казани была явлена милость Божия через чудесное обретение иконы
              Богоматери, получившей по этому случаю наименование Казанской.
            </p>

            <p>
              Матроне, девятилетней дочери стрельца Онучина, вознамерившегося
              поставить себе новый дом на месте пожарища, во сне неоднократно
              являлась Матерь Божия, повелевая сообщить архиепископу и
              градоначальникам, чтобы они извлекли из земли Ее образ, указав и
              место на пепелище, где надо было копать. Однако никто не обращал
              внимания на слова ребенка.
            </p>

            <p>
              Наконец, 8 июля, мать с помощниками стала рыть по указанию дочери,
              но только, когда сама девочка взялась за заступ и стала копать
              около печи, появилась икона Богоматери, обернутая в ветхий
              суконный рукав. Лик иконы был ясный, будто только что написанный,
              нисколько не пострадавший от огня (иконография Казанского образа
              представляет собой разновидность Одигитрии-Путеводительницы).
              Узнав о чуде, архиепископ с градоначальниками торжественным
              крестным ходом пришли на место чудесного обретения иконы и
              перенесли ее в церковь Николы Тульского, затем после молебна — в
              Благовещенский собор.{" "}
            </p>

            <p>
              События явления и перенесения образа описал патриарх Московский
              Ермоген, мученически пострадавший за Православие в смутное время.
              Ему принадлежит и служба в честь Казанского образа, включая
              общеизвестный тропарь: «Заступнице усердная…». Он также
              засвидетельствовал и первые чудеса, свершившиеся во время
              торжества: по дороге прозрел Иосиф, бывший слепым уже три года, в
              самом соборе исцелился другой слепец Никита. В последствии также
              была замечена особая милость Богоматери через Свой Казанский образ
              к страждущим глазами.{" "}
            </p>

            <p>
              На месте явления иконы был построен Богородицкий девичий
              монастырь. Первыми постриженицами обители стали отроковица Матрона
              и ее мать. Чудотворный образ был обложен царскими дарами —
              золотом, драгоценными камнями и жемчугом.
            </p>

            <p>
              Осенью, 22 октября (4 ноября по новому стилю) установлен праздник
              в благодарность за избавление Москвы и всей России от нашествия
              поляков.
            </p>

            <p>
              В 1612 году страна подверглась нападению польских войск, которые
              глумились над православной верой, грабили и жгли храмы. Обманным
              путем им удалось овладеть Москвой. По призыву святейшего патриарха
              Ермогена русский народ встал на защиту родины. В ополчение,
              которое возглавлял князь Димитрий Пожарский, был прислан из Казани
              чудотворный образ Пресвятой Богородицы.
            </p>

            <p>
              Весь народ и ополчение наложили на себя трехдневный пост и с
              молитвой обратились к Господу и Его Пречистой Матери за небесной
              помощью. Молитва была услышана. От находившегося в плену у поляков
              святителя Арсения (впоследствии епископа Суздальского) пришла
              весть, что ему в видении было открыто о перемене суда Божия на
              милость, по заступничеству Пресвятой Девы. Воодушевленные
              известием русские войска 22 октября 1612 года освободили Москву от
              польских захватчиков. Празднование в честь Казанской иконы
              Пресвятой Богородицы установлено в 1649 году. И до наших дней эта
              икона особо почитается русским православным народом.
            </p>

            <p>
              В нашем храме образ Казанской Божьей матери один из самых старых –
              конец 18века. Образ почитается за чудотворный, потому украшен
              дорогой ризой.
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Nech_rad}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Нечаянная радость</Card.Title>
            <p>
              {" "}
              Дни празднования: 9 декабря (22 декабря н.с.), 1 мая (14 мая н.с.)
            </p>

            <p>
              Икона Божией Матери, именуемая «Нечаянная Радость», пишется так: в
              комнате, вверху икона Божией Матери «Одигитрия», а внизу около нее
              коленопреклоненно молящийся юноша. Предание об исцелении некоего
              юноши от плотской страсти через эту святую икону описано в книге
              святителя Димитрия Ростовского «Руно Орошенное».
            </p>

            <p>
              Некий лихой человек вел грешную жизнь, однако был благоговейно
              привязан к Пречистой и ежедневно молился перед Ее иконой. Однажды,
              собравшись идти для греховного дела, он помолился и вдруг узрел,
              как у Младенца стали кровоточить язвы на руках, ногах и в боку.
              «О, Госпожа, кто это сделал?» На что Богородица отвечала: «Ты и
              прочие грешники грехами своими вновь распинаете Сына Моего». Тогда
              только раскрылась перед юношей бездна его грехопадения, долго
              молил он в слезах Богородицу и Спасителя о помиловании. Наконец,
              была дана ему нечаянная им уже радость прощения и оставления
              грехов. Молодой человек оставил греховную жизнь и до конца своих
              дней со слезами благодарил Богородицу за заступничество.
            </p>

            <p>
              Необходимо уточнить значение слова «нечаянный». В старину слово
              «чаять» означало «надеяться на что-либо, ожидать», то есть
              «нечаянная радость» означает «радость, на которую нельзя было
              надеяться».
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Skorop_icon}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Скоропослушница</Card.Title>
            <p>Дни празднования: 9 ноября (22 ноября н.с.</p>

            <p>
              Икона Божией Матери, именуемая «Скоропослушница» — древний
              чудотворный образ, находящийся на Святой Горе Афон в монастыре
              Дохиар.
            </p>

            <p>
              Монастырское предание относит время ее написания к Х веку, ко
              времени жизни настоятеля обители святого Неофита. В 1664 году
              трапезарь Нил, проходя в трапезную с зажженной дымящей лучиной,
              услышал от образа Богородицы, висевшего над дверью, голос,
              призывающий его впредь здесь не ходить и не коптить икону. Монах,
              решив, что это шутка кого-то из братии, пренебрег знамением и
              продолжал ходить в трапезную с коптящей лучиной. Внезапно он
              ослеп. В горьком раскаянии молился Нил перед иконой Божией Матери,
              умоляя о прощении. И вновь услышал чудный голос, извещавший о
              прощении и возвращении зрения, и приказывающий возвестить всей
              братии: «С этой поры будет именоваться сия икона Моя
              Скоропослушницею, потому что скорую всем, притекающим к ней, буду
              являть милость и исполнение прошений».
            </p>

            <p>
              Пресвятая Богородица исполнила и теперь исполняет Свое обещание —
              являет скорую помощь и утешение всем, с верою к Ней притекающим.
            </p>

            <p>
              В 1938 году Афонская обитель Дохиар принесла в дар Русской
              Духовной Миссии в Иерусалиме список с чудотворной иконы Божией
              Матери «Скоропослушница».
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Ierusalem_icon}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">
              Иерусалимская икона Божьей Матери
            </Card.Title>
            <p> Дни празднования: 12 октября (25 октября н.с.)</p>

            <p>
              Иерусалимская икона Божией Матери, по преданию, написана святым
              евангелистом Лукой на Святой Земле в Гефсимании в 15-ый год по
              вознесении Спасителя на небо. Композиционные особенности иконы
              таковы: Богоматерь с Младенцем на правой руке изображена по пояс и
              смотрит на обращенного к Ней Сына.
            </p>

            <p>
              В 463 году образ был перенесен в Константинополь. Заступничеством
              Иерусалимской иконы Пресвятой Богородицы византийские войска
              отразили нападение скифов. В память о таком чуде икону перенесли в
              один из главных храмов Византии — Влахернскую церковь.
            </p>

            <p>
              В 988 году икона была принесена в Корсунь и подарена святому
              равноапостольному князю Владимиру. Когда новгородцы приняли
              христианство, то святой Владимир отправил им этот образ. Более
              четырехсот лет икона Богородицы пребывал в Софийском соборе
              Великого Новгорода.
            </p>

            <p>
              Иоанн Грозный в 1571 году перенес икону в Московский Успенский
              собор. Окруженная всеобщим почитанием, икона пребывала в Кремле,
              пока не исчезла в 1812 году. По некоторым сведениям, святыня была
              увезена французами и помещена в соборе Парижской Богоматери.
              Согласно другим данным, она хранится в Ватикане. Однако весомых
              доказательств как первой, так и второй версии нет.
            </p>

            <p>
              На утешение москвичам осталось два точных списка с Иерусалимской
              иконы Богородицы. Первый был принесен в Кремль из церкви Рождества
              Богородицы на Сенях и помещен в Успенском соборе за патриаршим
              местом. Второй — пребывает в никогда не закрывавшемся храме
              Рождества Христова в Измайлове, его принесли сюда из соседнего
              Покровского собора царской усадьбы на Серебряно-Виноградском
              острове.
            </p>

            <p>
              Правый предел нашего храма освящен в честь иконы Божией Матери
              «Иерусалимская».
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Vzpog_icon}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Взыскание погибших</Card.Title>
            <p> Дни празднования: 5 февраля (18 февраля н.с.)</p>

            <p>
              Когда и как возник образ Богородицы, именуемый «Взыскание
              погибших», никто не знает. А вот название ей такое дал Феофил
              Аданский. По преданию жил он в VI веке, служил экономом в
              архиерейском доме, был очень порядочным и добросовестно выполнял
              свою работу. После смерти епископа Феофила его пытались назначить
              на освободившуюся должность, но он отказался поскольку считал себя
              недостойным. Однажды завистники обвинили эконома в краже, и новый
              епископ отстранил его от должности. Возроптал он на Бога, и в душе
              зародилась мысль о мести. Он отрекся от Господа и стал служить
              сатане. Епископ со временем решил вернуть его на службу и тогда
              Феофил очнулся от дьявольского пленения. Он уединился и несколько
              дней взывал к Богородице о прощении и спасении его, «погибающего»,
              стоя на коленях перед её иконой и называя Её «взысканием
              погибших». Наконец, молитвы были услышаны, а Феофил прощен.
            </p>

            <p>
              В России первые чудеса этой иконы были явлены в XVIII веке.
              Борского крестьянина Федота Обухова в дороге застигла пурга,
              лошади не могли продолжать путь. Замерзая, Федот начал молиться
              Пресвятой Богородице и пообещал, если выживет, написать список её
              образа «Взыскание погибших». Вскоре его случайно обнаружили
              местные жители, и Федот чудом остался жив. Когда же он пришел к
              иконописцу заказать икону, тот запросил за неё очень высокую цену,
              за что Богородица в тот же момент лишила его зрения. Он раскаялся
              в корыстолюбии и согласился написать образ за любую стоимость.
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Ahtyr_icon}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">
              Ахтырская икона Божьей Матери
            </Card.Title>
            <p>Дни празднования: 2 июля (15 июля н.с.) </p>

            <p>
              На иконе Божией Матери именуемой «Ахтырская» мы видим поясное
              изображение Богородицы, молитвенно сложившей руки на груди. Справа
              от нее крест с распятым Иисусом Христом.{" "}
            </p>

            <p>
              Ахтырская икона получила наименование от названия небольшого
              города Ахтырка в Харьковской губернии. В этом городке был храм в
              честь Покрова Пресвятой Богородицы, где в первой половине XVIII
              века служил благочестивый и добродетельный священник Василий
              Данилов. Летом 1739 года отец Василий косил траву около своего
              дома и внезапно в густой высокой траве увидел светящуюся
              необыкновенным светом икону, на которой была изображена Божия
              Матерь, молящаяся перед Распятием. В благоговейном страхе отец
              Василий опустился на колени и стал молиться Божией Матери, а затем
              отнес икону в свой дом.{" "}
            </p>

            <p>
              {" "}
              Через три года отец Василий вновь увидел от иконы чудесное сияние,
              которое впоследствии неоднократно повторялось. Вскоре в сонном
              видении он получил указание обмыть икону и покрыть покрывалом.
              Пробудившись, он немедленно исполнил повеление. Воду, которой
              обмывалась икона, отец Василий хотел утром вылить в реку, но ему
              вторично явилась Божия Матерь и повелела не выливать воду, т. к.
              она исцеляет от лихорадки. Тогда отец Василий напоил этой водой
              свою дочь, болевшую лихорадкой, и она исцелилась. Многие больные,
              испив воды, также исцелились.
            </p>

            <p>
              {" "}
              Известие о новой чудотворной иконе быстро распространилось. После
              расследований чудесных исцелений в 1751 году Святейший Синод
              постановил почитать новоявленную Ахтырскую икону чудотворной. В
              Ахтырке был заложен величественный собор Покрова Пресвятой
              Богородицы по проекту архитектора Растрелли. По одному из
              преданий, эта икона была с Петром Великим в Полтавском походе в
              1709 году, следовательно, еще до своего вторичного чудесного
              явления. В 1917 году чудотворная икона была утрачена.
            </p>
          </Card.Body>
        </Card>

        <Card border="danger" style={{ margin: "2%" }}>
          <Card.Img
            variant="top"
            src={Niko}
            style={{
              maxWidth: "30%",
              display: "block",
              margin: "2% auto",
              borderRadius: "5%",
            }}
          />
          <Card.Body>
            <Card.Title className="red-text">Николай Чудотворец</Card.Title>

            <p>
              Дни празднования: 9 мая (22 мая н.с.), 29июля (11 августа н.с.), 6
              декабря (19 декабря н.с.).
            </p>

            <p>
              Святитель Николай, архиепископ Мир Ликийских, чудотворец,
              прославился как великий угодник Божий. Он родился в городе Патаре
              Ликийской области. Плод долгих молитв ко Господу бездетных
              благочестивых родителей Феофана и Нонны, младенец Николай со дня
              рождения своего явил людям свет будущей своей славы великого
              чудотворца. Мать его после родов сразу исцелилась от болезни.
              Новорожденный младенец еще в купели крещения простоял на ногах три
              часа, никем не поддерживаемый, воздавая этим честь Пресвятой
              Троице.
            </p>

            <p>
              С детских лет Николай преуспевал в изучении Божественного Писания;
              днем он не выходил из храма, а ночью молился и читал книги. Вскоре
              юноша был поставлен чтецом, а потом возведен в сан священника.
            </p>

            <p>
              Узнав о горькой нужде и нищете одного ранее богатого жителя его
              города, святой Николай спас его от большого греха. Имея трех
              взрослых дочерей, отчаявшийся отец замыслил отдать их на
              блудодеяние для спасения от голода. Святитель, скорбя о погибающем
              грешнике, ночью тайно бросил ему в окно три мешочка с золотом и
              тем спас семью от падения и духовной гибели. Творя милостыню,
              святитель Николай всегда старался сделать это тайно и скрыть свои
              благодеяния.
            </p>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default SvGoPage;
