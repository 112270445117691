import React, { Component } from "react";
import  { Get } from "react-axios";
import { Card, Table } from "react-bootstrap";

import "./ListGoPage.css";

class ListGoPage extends Component {
  
  render() {
    let resp = [];
    return (
      <div>
      <Card border="danger" >
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title className="red-text"><b>Расписание Богослужений</b></Card.Title>
          <hr />
        <code>
          <Get url="/api/list">
            {(error, response, isLoading, makeRequest, axios) => {
              if (error) {
                return (
                  <div>
                    Ошибка загрузки данных: {error.message}{" "}
                    <button
                      onClick={() => makeRequest({ params: { reload: true } })}
                    >
                      Повторить попытку
                    </button>
                  </div>
                );
              } else if (isLoading) {
                return <div>Загрузка...</div>;
              } else if (response !== null) {
                resp = (response.data);
            
                return (
                  <div>
                    {resp.map((person) => (
                      <React.Fragment key={person._id}>
                      <Table responsive variant="light" borderless key={person.id}>
                        <thead>
                          <tr>
                            <th className="table1">
                              <b>{person.date} </b>
                            </th>
                            <th className="table2">
                              <b>{person.prazdnik}</b>{" "}
                            </th>
                            <th className="table3">
                             
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th className="table1">{person.time1}</th>
                            <th className="table2">{person.event1}</th>
                            <th className="table3">{person.cleric1}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="table1">{person.time2}</td>
                            <td className="table2">{person.event2}</td>
                            <td className="table3">{person.cleric2}</td>
                          </tr>
                          <tr>
                            <td className="table1">{person.time3}</td>
                            <td className="table2">{person.event3}</td>
                            <td className="table3">{person.cleric3}</td>
                          </tr>
                        </tbody>
                       
                      </Table>
                       </React.Fragment>
                    ))}
                    <button
                      onClick={() => makeRequest({ params: { refresh: true } })}
                    >
                      Обновить
                    </button>
                  </div>
                );
              }
              return <div>Default message before request is made.</div>;
            }}
          </Get>
          </code>
        </Card.Body>
      </Card>
    </div>

    );
  }
}

export default ListGoPage;
