import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from '../../background.jpg';




import "./NewsPage.css";

function NewsPage() {

    return (
      <div>
   <Card border="danger" style={{  margin: '2%' }}>
   <Card.Img variant="top" src={Logo} />
  <Card.Body>
    <Card.Title>Новости</Card.Title>
    <Card.Text>
       Новости Храма
       
           
    </Card.Text>
    <a href="/news" class="btn btn-danger">
              Перейти
            </a>  
  </Card.Body>
  </Card>
      </div>
    );

}

export default NewsPage;
