import React, { Component, useState } from "react";
import { Card, Button, Table, Form } from "react-bootstrap";
import Logo from "../../background.jpg";
import OrderTrebaPage from "../OrderTrebaPage/OrderTrebaPage";
import { Link } from "react-router-dom";
import payQRCode from "../../qrcode.jpg";

import "./PayGoPage.css";
import axios from "axios";

let summ = 0;
let payLink = "https://securepayments.sberbank.ru/shortlink/VpPrwYhG";
let treba = [
  { treba: "Пожертвовать сумму (оплата картой)", sum: 999, total: true, sName: false },
  { treba: "Пожертвовать сумму (оплата QR кодом)", sum: 999, total: true, sName: false },
  {
    treba: "Сорокоуст в Иерусалим о здравии",
    sum: 500,
    total: false,
    sName: false,
  },
  {
    treba: "Сорокоуст в Иерусалим об упокоении",
    sum: 500,
    total: false,
    sName: false,
  },
  { treba: "Проскомидия о здравии", sum: 10, total: false, sName: false },
  { treba: "Проскомидия о упокоении", sum: 10, total: false, sName: false },
  { treba: "Литургия о здравии", sum: 80, total: true, sName: false },
  { treba: "Литургия о упокоении", sum: 80, total: true, sName: false },
  { treba: "Молебен о здравии", sum: 100, total: true, sName: true },
  {
    treba: "Молебен о здравии с водосвятием",
    sum: 120,
    total: true,
    sName: true,
  },
  { treba: "Панихида о упокоении", sum: 100, total: true, sName: false },
  { treba: "Сорокоуст о здравии", sum: 400, total: false, sName: false },
  { treba: "Сорокоуст о упокоении", sum: 400, total: false, sName: false },
  { treba: "Псалтирь 40 дней о здравии", sum: 500, total: false, sName: false },
  {
    treba: "Псалтирь 40 дней о упокоении",
    sum: 500,
    total: false,
    sName: false,
  },
  { treba: "Помин 1 месяц о здравии", sum: 200, total: false, sName: false },
  { treba: "Помин 1 месяц о упокоении", sum: 200, total: false, sName: false },
  { treba: "Помин 3 месяца о здравии", sum: 500, total: false, sName: false },
  { treba: "Помин 3 месяца о упокоении", sum: 500, total: false, sName: false },
  { treba: "Помин 6 месяцев о здравии", sum: 900, total: false, sName: false },
  {
    treba: "Помин 6 месяцев о упокоении",
    sum: 900,
    total: false,
    sName: false,
  },
  {
    treba: "Помин 12 месяцев о здравии",
    sum: 2000,
    total: false,
    sName: false,
  },
  {
    treba: "Помин 12 месяцев о упокоении",
    sum: 2000,
    total: false,
    sName: false,
  },
];

const PayGoPage = (props) => {
  const handleClick = (index, e) => {
    e.preventDefault();
    if (index === 0) {setState(1000);    
      window.open(payLink, "_blank", "noopener,noreferrer");}
      else  if (index === 1) {setState(1000); window.open(payQRCode);   
        }
    else setState(index);
  };

  const [state, setState] = useState({ state: -1 });
  // -1 initial state
  // >=0 && <1000 treba
  // 1000 Zapiska sent
  const [zs, setZs] = useState({ zs: false });

  let res = treba.map((item, index) => {
    return (
      <tr key={index} onClick={(e) => handleClick(index, e)}>
        <td>{item.treba}</td>
        <td>
          {index > 1 && JSON.stringify(item.sum)}
          {item.total && item.sum !== 999 && " руб., записка"}
          {item.sum === 999 && "Произвольная сумма"}
          {!item.total && " руб., имя"}
        </td>
      </tr>
    );
  });

  // ----------------------------------------
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
    sendMail();
  };
  // ----------------------------------------
  const payQR = () => {
    window.open(payQRCode);
    sendMail();
  };
  // ---------------------------------------

  const [allValues, setAllValues] = useState({
    id: "",
    date: "",
    sName: "",
    name1: "",
    name2: "",
    name3: "",
    name4: "",
    name5: "",
    name6: "",
    name7: "",
    name8: "",
    name9: "",
    name10: "",
  });
  // ---------------------------------------
  const sendRes = async (art) => {
    return await axios.post("/api/sendmail", art);
  };

  const sendMail = async () => {
    console.log('state:'+state);
    let bodyL = "<center><H3>" + treba[state].treba + "</H3><br>";

    if (allValues.sName.length > 0)
      bodyL = bodyL + "<center><H3>" + allValues.sName + "</H3><br>";

    bodyL += "<br><p width='60%' style='border:3px black solid;'>";
    if (allValues.name1.length > 0) bodyL += allValues.name1 + "<br>";
    if (allValues.name2.length > 0) bodyL += allValues.name2 + "<br>";
    if (allValues.name3.length > 0) bodyL += allValues.name3 + "<br>";
    if (allValues.name4.length > 0) bodyL += allValues.name4 + "<br>";
    if (allValues.name5.length > 0) bodyL += allValues.name5 + "<br>";
    if (allValues.name6.length > 0) bodyL += allValues.name6 + "<br>";
    if (allValues.name7.length > 0) bodyL += allValues.name7 + "<br>";
    if (allValues.name8.length > 0) bodyL += allValues.name8 + "<br>";
    if (allValues.name9.length > 0) bodyL += allValues.name9 + "<br>";
    if (allValues.name10.length > 0)
      bodyL += allValues.name10 + "<br></p></center>";

    const article = { title: treba[state].treba, body: bodyL };
    const resp = await sendRes(article);
    setZs(true);
    setState(-1);
    if (resp) {
      console.log("Ваша записка успешно отправлена! ");
    } else console.log("Ошибка отправки записки!");
  };
  // ----------------------------------------
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    if (treba[state].total) summ = treba[state].sum;
    else {
      summ =
        Math.sign(allValues.name1.length) * treba[state].sum +
        Math.sign(allValues.name10.length) * treba[state].sum +
        Math.sign(allValues.name2.length) * treba[state].sum +
        Math.sign(allValues.name3.length) * treba[state].sum +
        Math.sign(allValues.name4.length) * treba[state].sum +
        Math.sign(allValues.name5.length) * treba[state].sum +
        Math.sign(allValues.name6.length) * treba[state].sum +
        Math.sign(allValues.name7.length) * treba[state].sum +
        Math.sign(allValues.name8.length) * treba[state].sum +
        Math.sign(allValues.name9.length) * treba[state].sum;
    }
  };
  let saintName = (
    <>
      <Form.Label>
        <b>Введите имя Святого</b>
      </Form.Label>
      <Form.Control
        placeholder="Имя святого"
        name="sName"
        onChange={changeHandler}
        value={allValues.sName}
        className="red-text"
      />
    </>
  );
  // define var AllList
  let allList = (
    <Card border="danger" style={{ margin: "2%" }}>
      <Card.Img
        variant="top"
        src={Logo}
        style={{
          width: "50%",
          display: "block",
          margin: "2% auto",
          borderRadius: "5%",
        }}
      />
      <Card.Body>
        <Card.Title className="red-text">Пожертвование</Card.Title>
        <Card.Text as="div" className="red-text">
          Нажмите на название требы для заказа
          <Table responsive bordered striped>
            <thead>
              <tr>
                <th>
                  <b>Заказ Требы</b>
                </th>
                <th>
                  <b>Стоимость</b>{" "}
                </th>
              </tr>
            </thead>

            <tbody>{res}</tbody>
          </Table>
        </Card.Text>
      </Card.Body>
    </Card>
  );
  // end of var All List
  if (state >= 0 && state < 1000)
    return (
      <div className="red-text">
        <hr />
        Заказ требы - {treba[state].treba}, стоимость {treba[state].sum}{" "}
        {treba[state].total && "руб.  записка"}{" "}
        {!treba[state].total && "руб. имя"}
        <hr />
        {treba[state].sName === true && saintName}
        <Form.Label>
          <b>Введите не более 10 имен</b>
        </Form.Label>
        <Form.Control
          placeholder="Имя "
          name="name1"
          onChange={changeHandler}
          value={allValues.name1}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name2"
          onChange={changeHandler}
          value={allValues.name2}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name3"
          onChange={changeHandler}
          value={allValues.name3}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name4"
          onChange={changeHandler}
          value={allValues.name4}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name5"
          onChange={changeHandler}
          value={allValues.name5}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name6"
          onChange={changeHandler}
          value={allValues.name6}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name7"
          onChange={changeHandler}
          value={allValues.name7}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name8"
          onChange={changeHandler}
          value={allValues.name8}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name9"
          onChange={changeHandler}
          value={allValues.name9}
          className="red-text"
        />
        <Form.Control
          placeholder="Имя "
          name="name10"
          onChange={changeHandler}
          value={allValues.name10}
          className="red-text"
        />
        <hr />
        Всего стоимость составляет {summ} рублей.
        <hr />
        <div className="red-text">
        <hr />
        ПОСЛЕ УСПЕШНОЙ ОПЛАТЫ ЗАПИСКА БУДЕТ ОТПРАВЛЕНА В ХРАМ
        <hr />
        <Button variant="danger" onClick={() => openInNewTab(payLink)}>
          Оплатить картой
        </Button>
        <hr />
        <Button
          variant="danger"
          onClick={() => {
            payQR();
          }}
        >
          Оплатить QR кодом
        </Button>
        <hr />
        <Button variant="secondary" onClick={() => setState({ state: -1 })}>
          Перейти к списку треб
        </Button>
      </div>
      </div>
    );
  
  else return <div>{allList}</div>;
};

export default PayGoPage;
